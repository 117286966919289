import { createTheme } from '@mui/material/styles';


export const colors = {
  primary: '#000000',
  secondary: '#0e117f',

  deepBlue: '#0e117f',
  vividBlue: '#0137fd',
  brightCyan: '#19aec3',
  warmOrange: '#ff7f11',
  lightGray: '#f4f4f4',
  textGray: '#969696',
  subscription1: {
    active: "#fe8541",
    inactiveDark: "#FFB78A",  // mniej wyciszony
    inactiveLight: "#FFE1CF" // bardziej wyciszony
  },
  subscription2: {
    active: "#07b3c4",
    inactiveDark: "#69CAD0",
    inactiveLight: "#B3E6E9"
  },
  subscription3: {
    active: "#016dd6",
    inactiveDark: "#6F9ED9",
    inactiveLight: "#B3CFEF"
  },
  subscription4: {
    active: "#484848",
    inactiveDark: "#8E8E8E",
    inactiveLight: "#D3D3D3"
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    }

    // ... więcej kolorów do palety
  },
  // własne właściwości, które można wykorzystać w całej aplikacji
});

export default theme;
