const API_BASE_URL = 'https://navibudbedev.azurewebsites.net';
const LOCAL_API_BASE_URL = 'https://localhost:5001';

export const API_ENDPOINTS = {
    BASE_URL: process.env.NODE_ENV === 'development' ? LOCAL_API_BASE_URL : API_BASE_URL,
    REGISTER_TEST: '/users/register-test-account',
    CONTACT: '/api/contact',
    SUBSCRIBE: '/api/subscription/subscribe',
    COUNT_INVESTMENTS: '/api/investments/countInvestments'
};

export const API_EXTERNAL_ENDPOINTS = {
    CITY_SEARCH: 'https://nominatim.openstreetmap.org/search?format=json&q=',
    CITY_REVERSE_SEARCH: 'https://nominatim.openstreetmap.org/reverse?format=json'
};
