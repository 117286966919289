import React, { useEffect } from 'react';
import { Alert, AlertColor, Backdrop, Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon, Snackbar, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import MapComponent, { Position } from '../Map/MapComponent';
import { API_ENDPOINTS } from '../../utils/apiConfig';

const validationSchema = Yup.object({
    companyName: Yup.string().required('Nazwa firmy jest wymagana'),
    nip: Yup.string()
        .matches(/^[0-9]{10}$/, 'NIP musi składać się z 10 cyfr')
        .required('NIP jest wymagany'),
    email: Yup.string().email('Niepoprawny adres email').required('Email jest wymagany'),
    password: Yup.string()
        .required('Hasło jest wymagane')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,;:])[A-Za-z\d@$!%*?&.,;:]{8,}$/,
            "Hasło musi zawierać co najmniej 8 znaków, jedną wielką literę, jedną małą literę, cyfrę i znak specjalny")
});


const TestAppForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarType, setSnackbarType] = React.useState<AlertColor>('success'); // 'success' or 'error'
    const [loading, setLoading] = React.useState(false);
    const [coordinates, setCoordinates] = React.useState<Position | null>(null);

    const handleMapChange = (position: Position) => {
        setCoordinates(position);
    };

    const handleCancel = () => {
        formik.resetForm(); // Czyści formularz
        navigate('/'); // Przenosi do głównej strony
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            companyName: '',
            nip: '',
            email: '',
            password: ''
        },
        validationSchema: validationSchema,

        onSubmit: async (values, { resetForm }) => {
            const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.REGISTER_TEST}`;
            const userCoordinates = await getUserLocation();
            const finalCoordinates = coordinates || userCoordinates;
            const payload = {
                user: { userName: values.email, password: values.password, email: values.email },
                position: { latitude: finalCoordinates.lat, longitude: finalCoordinates.lng }
            };
            setLoading(true);
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.message || "Unknown error");
                }

                const data = await response.json();
                setSnackbarMessage('Formularz został pomyślnie wysłany!');
                setSnackbarType('success');
                setSnackbarOpen(true);
                resetForm();
            } catch (error: unknown) {
                let errorMessage = 'Wystąpił błąd podczas wysyłania formularza.';
                if (error instanceof Error) {
                    errorMessage = 'Błąd serwera: ' + error.message;
                }
                setSnackbarMessage(errorMessage);
                setSnackbarType('error');
                setSnackbarOpen(true);
            } finally {
                setLoading(false);
            }

        },
    });

    const getDefaultLocation = () => {
        // Domyślna lokalizacja - Kalisz
        return { lat: 51.7666636, lng: 18.083333 };
    };

    const getUserLocation = () => {
        return new Promise<Position>((resolve) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
                },
                () => {
                    resolve(getDefaultLocation());
                }
            );
        });
    };

    const checkPasswordCriteria = {
        length: formik.values.password.length >= 8,
        upper: /[A-Z]/.test(formik.values.password),
        lower: /[a-z]/.test(formik.values.password),
        number: /\d/.test(formik.values.password),
        special: /[@$!%*?&]/.test(formik.values.password),
    };


    return (
        <Box sx={{ p: 2, height: '100%' }}>
            <Typography variant="h4" sx={{ mb: 4 }}>Zarejestruj konto testowe</Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>Po wysłaniu formularza otrzymasz e-mail z potwierdzeniem założenia konta. Wiadomość będzie również zawierać link do potwierdzenia Twojego adresu e-mail.</Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>Dostęp do konta testowego jest ograniczony do 7 dni.</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} sx={{ alignItems: 'flex-start' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', textAlign: 'center' }}>Formularz rejestracyjny</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="companyName"
                                    name="companyName"
                                    label="Nazwa firmy"
                                    value={formik.values.companyName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.companyName && Boolean(formik.errors.companyName)}
                                    helperText={formik.touched.companyName && formik.errors.companyName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="nip"
                                    name="nip"
                                    label="NIP"
                                    value={formik.values.nip}
                                    onChange={formik.handleChange}
                                    error={formik.touched.nip && Boolean(formik.errors.nip)}
                                    helperText={formik.touched.nip && formik.errors.nip}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Hasło"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />
                                <List>
                                    {Object.entries(checkPasswordCriteria).map(([key, isValid]) => (
                                        <ListItem key={key} disablePadding>
                                            <ListItemIcon>
                                                {isValid && <CheckCircleOutlineIcon style={{ color: 'green' }} />}
                                            </ListItemIcon>
                                            <Typography sx={{ fontSize: '0.75rem' }}>
                                                {key === 'length' ? "Co najmniej 8 znaków" :
                                                    key === 'upper' ? "Przynajmniej jedna wielka litera (A-Z)" :
                                                        key === 'lower' ? "Przynajmniej jedna mała litera (a-z)" :
                                                            key === 'number' ? "Przynajmniej jedna cyfra (0-9)" :
                                                                "Przynajmniej jeden znak specjalny (@$!%*?&)"}
                                            </Typography>
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '0.9em'  }}>
                            Proszę wybrać punkt na mapie, który będzie centrum obszaru działania aplikacji. Wybrany punkt określi obszar o promieniu 10 km, który obejmie teren działania Twojej licencji testowej. Możesz również wyszukać lokalizację wpisując nazwę miasta w polu wyszukiwania.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2, fontSize: '0.7em' }}>
                            (Jeżeli nie wybierzesz punktu, aplikacja spróbuje użyć lokalizacji dostarczonej przez Twoją przeglądarkę. W przypadku braku dostępu do danych geolokalizacyjnych, jako domyślną lokalizację ustawione zostanie centrum Kalisza. Wyszukując miasto, mapa automatycznie skupi się na wybranej lokalizacji.)
                        </Typography>
                        <MapComponent position={coordinates} onLocationSelect={handleMapChange} radius={10} mapHeight='250px'/>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
                        <Button variant="outlined"
                            sx={{
                                borderRadius: 0,
                                '&:hover': {
                                    color: '#ff7f11',
                                    borderColor: '#ff7f11'
                                }
                            }} onClick={handleCancel}>Powrót</Button>
                        <Button type="submit" variant="contained" sx={{ ml: 5, backgroundColor: '#ff7f11', borderRadius: 0 }} >Testuj</Button>
                    </Grid>
                </Grid>
                {loading && (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </form>
            <Snackbar open={snackbarOpen} autoHideDuration={8000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );

};

export default TestAppForm;
