import React, { RefObject, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link, MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import plFlag from '../../content/images/flags/poland.png';
import enFlag from '../../content/images/flags/united-kingdom.png';
import uaFlag from '../../content/images/flags/ukraine.png';
import logoHeader from '../../content/images/logo_header.png';
import { Resource } from '../../resources';

interface HeaderProps {
  contactRef: RefObject<HTMLDivElement>;
}

const Header: React.FC<HeaderProps> = ({ contactRef }) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [scrollToContactFlag, setScrollToContactFlag] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<'pl' | 'en'>('pl');
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!i18n.language) {
      i18n.changeLanguage('pl');
    }

    if (scrollToContactFlag && location.pathname === '/') {
      contactRef.current?.scrollIntoView({ behavior: 'smooth' });
      setScrollToContactFlag(false);
    }
  }, [location, scrollToContactFlag, contactRef, i18n]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollToContact = () => {
    if (location.pathname !== '/') {
      setScrollToContactFlag(true);
      navigate('/');
    } else {
      contactRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    handleCloseNavMenu();
  };

  const toggleLanguage = () => {
    const nextLanguage = currentLanguage === 'pl' ? 'en' : 'pl';
    setCurrentLanguage(nextLanguage);
    i18n.changeLanguage(nextLanguage);
  };

  const getFlag = (language: 'pl' | 'en') => {
    switch (language) {
      case 'pl':
        return plFlag;
      case 'en':
        return enFlag;
      default:
        return plFlag;
    }
  };

  return (
    <AppBar position="static" color="default" sx={{ bgcolor: 'white', boxShadow: 'none' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'start' }}
          >
            <Link
              href="/"
              title="Strona główna - NaviBud"
              underline="none"
              color="inherit"
            >
              <img
                src={logoHeader}
                alt="NaviBud - Navi-Bud Logo"
                style={{ height: '5vh', minHeight: '30px' }}
              />
            </Link>
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' }, marginLeft: 'auto' }}>
            <Button onClick={scrollToContact}
              sx={{
                my: 2,
                color: 'black',
                display: 'block',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: '#ff7f11',
                  color: '#fff',
                },
              }}>
              {t(Resource.Buttons.Contact)}
            </Button>
            <Button
              onClick={() => {
                navigate('/cennik');
                handleCloseNavMenu();
              }}
              sx={{
                my: 2,
                color: 'black',
                display: 'block',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: '#ff7f11',
                  color: '#fff'
                },
              }}>
              {t(Resource.Buttons.PriceList)}
            </Button>
            <Button
              component="a"
              href="https://app.navi-bud.pl/login"
              rel="noopener noreferrer nofollow"
              sx={{
                my: 2,
                color: 'black',
                display: 'block',
                borderRadius: 0,
                '&:hover': {
                  backgroundColor: '#ff7f11',
                  color: '#fff',
                },
              }} >
              {t(Resource.Buttons.Login)}
            </Button>
            <Button
              onClick={toggleLanguage}
              sx={{
                margin: '0 16px',
                padding: 0,
                minWidth: 'auto',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
              }}>
              <img src={getFlag(currentLanguage)} alt={currentLanguage} style={{ width: 32, height: 32 }} />
            </Button>
            <Button
              onClick={() => {
                navigate('/testuj-aplikacje')
                handleCloseNavMenu();
              }}
              variant="contained"
              sx={{
                backgroundColor: '#ff7f11',
                borderRadius: 0,
                margin: 2,
              }}
            >
              {t(Resource.CtaTestSection.CtaButton.Text)}
            </Button>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: 'auto' }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <MenuItem onClick={scrollToContact}>
              <Typography textAlign="center">{t(Resource.Buttons.Contact)}</Typography>
            </MenuItem>
            <MenuItem onClick={() => {
              navigate('/cennik');
              handleCloseNavMenu();
            }}>
              <Typography textAlign="center">{t(Resource.Buttons.PriceList)}</Typography>
            </MenuItem>
            <MenuItem component="a" href="https://app.navi-bud.pl/login" rel="noopener noreferrer nofollow">
              <Typography textAlign="center">{t(Resource.Buttons.Login)}</Typography>
            </MenuItem>
            <MenuItem onClick={toggleLanguage}>
              <Button
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <img src={getFlag(currentLanguage)} alt={currentLanguage} style={{ width: 32, height: 32 }} />
              </Button>
            </MenuItem>
            <MenuItem onClick={() => {
              navigate('/testuj-aplikacje');
              handleCloseNavMenu();
            }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#ff7f11',
                  borderRadius: 0,
                  margin: 0,
                }}
              >
                {t(Resource.CtaTestSection.CtaButton.Text)}
              </Button>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
