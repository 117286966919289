import React, { useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import { colors } from '../../theme';
import { Position } from '../Map/MapComponent';
import PaymentFormSection from '../PaymentFormSection/PaymentFormSection';
import TestAppBottomSection from '../TestAppSection/TestAppBottomSection';
import SubscriptionDetail from './SubscriptionDetail';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import MapPopup from '../Map/MapPopup';
import RoomIcon from '@mui/icons-material/Room';

const plansData = [
  {
    id: 1,
    name: 'Lokalny Start',
    price: '400 zł',
    range: 'Zasięg: do 50 km',
    details: ['Zasięg do 50 km', 'Standardowe funkcje', 'Podstawowe wsparcie'],
    color: colors.subscription1.active,
    inactiveColorLight: colors.subscription1.inactiveLight,
    inactiveColorDark: colors.subscription1.inactiveDark,
    isBuyable: true,
    radius: 50
  },
  {
    id: 2,
    name: 'Regionalny Rozwój',
    price: '550 zł',
    range: 'Zasięg: do 100 km',
    details: ['Zasięg do 100 km', 'Zaawansowane funkcje', 'Analizy i raporty'],
    color: colors.subscription2.active,
    inactiveColorLight: colors.subscription2.inactiveLight,
    inactiveColorDark: colors.subscription2.inactiveDark,
    isBuyable: true,
    radius: 100
  },
  {
    id: 3,
    name: 'Maksymalny Zasięg',
    price: '800 zł',
    range: 'Zasięg: do 150 km',
    details: ['Zasięg do 150 km', 'Wszystkie funkcje', 'Wsparcie premium'],
    color: colors.subscription3.active,
    inactiveColorLight: colors.subscription3.inactiveLight,
    inactiveColorDark: colors.subscription3.inactiveDark,
    isBuyable: true,
    radius: 150
  },
  {
    id: 4,
    name: 'Twój Plan',
    price: '??? zł',
    range: '',
    details: ['Dostosowany zasięg', 'Modułowa konstrukcja pakietu'],
    color: colors.subscription4.active,
    inactiveColorLight: colors.subscription4.inactiveLight,
    inactiveColorDark: colors.subscription4.inactiveDark,
    hasSlider: true,
    isBuyable: false,
    radius: 100
  }
];


const SubscriptionList: React.FC = () => {
  const [selectedPlanId, setSelectedPlanId] = useState<number>(1);
  const [selectedRadius, setSelectedRadius] = useState<number | null>(null);
  const [selectedPlanName, setSelectedPlanName] = useState<string | null>(null);
  const [selectedCityName, setSelectedCityName] = useState<string | null>(null); // Track selected city name
  const paymentFormRef = useRef<HTMLDivElement>(null);
  const [selectedPosition, setSelectedPosition] = useState<Position>({ lat: 51.7666636, lng: 18.083333 });
  const [mapPopupOpen, setMapPopupOpen] = useState<boolean>(false);

  const handleOpenMapPopup = () => {
    setMapPopupOpen(true);
  };

  const handleCloseMapPopup = () => {
    setMapPopupOpen(false);
  };

  const handleConfirmMapSelection = (position: Position, cityName: string | null) => {
    handleCitySelection(position, cityName); // Update selected city and position
    handleCloseMapPopup(); // Close the popup after confirming
  };

  const handlePlanSelect = (id: number, name: string, radius: number) => {
    setSelectedPlanId(id);
    setSelectedPlanName(name);
    setSelectedRadius(radius);
  };

  const handleFetchPoints = (planId: number, position: Position, radius: number) => {
    // Perform the API call to fetch the number of points (buildings) based on the selected position and radius
    fetchInvestmentCount(planId, position.lat, position.lng, radius);
  };

  const fetchInvestmentCount = async (planId: number, lat: number, lng: number, distanceInKm: number) => {
    try {
      const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.COUNT_INVESTMENTS}?latitude=${lat}&longitude=${lng}&distanceInKm=${distanceInKm}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to fetch investment count');
      const count = await response.json();
      // Store the count or update state as needed
    } catch (error) {
      console.error('Error fetching investment count:', error);
    }
  };

  const handleScrollToForm = () => {
    if (paymentFormRef.current) {
      paymentFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCitySelection = (position: Position, cityName: string | null) => {
    setSelectedPosition(position);
    setSelectedCityName(cityName);
  };

  return (
    <Box sx={{ flexGrow: 1, marginY: 10 }}>
      <TestAppBottomSection />
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 3 }}>
        Dostosuj swój zasięg!
      </Typography>
      <Typography variant="body2" sx={{ color: colors.textGray, textAlign: 'center', marginBottom: 3 }}>
        Możesz zmienić domyślne miasto, wybierając lokalizację na mapie – wpisz nazwę miasta w polu wyszukiwania lub kliknij bezpośrednio na mapie, aby wskazać miejsce. Na mapie zobaczysz zasięg wybranego pakietu (50 km, 100 km, 150 km itd.), a po zatwierdzeniu wybranej lokalizacji obliczymy dla Ciebie liczbę budów w wybranym zasięgu.
      </Typography>
      <Typography variant="body1" sx={{ color: colors.warmOrange, textAlign: 'center', marginBottom: 0 }}>
        Wybrane miasto:
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 'bold', color: colors.warmOrange, textAlign: 'center', marginBottom: 3 }}>
        {selectedCityName ? selectedCityName : 'Kalisz (domyślnie)'}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Button
          sx={{
            borderColor: colors.warmOrange,
            color: colors.warmOrange,
            backgroundColor: 'transparent',
            borderRadius: 0,
            '&:hover': {
              borderColor: colors.warmOrange,
              bgColor: 'transparent',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'none',
          }}
          size="small"
          variant="outlined"
          onClick={handleOpenMapPopup}
          startIcon={<RoomIcon sx={{ color: colors.warmOrange }} />} // Add the icon here
        >
          Zmień miasto
        </Button>
      </Box>
      <Grid container spacing={2}>
        {plansData.map(plan => (
          <Grid item xs={12} sm={6} md={3} key={plan.id}>
            <SubscriptionCard
              plan={plan}
              selected={plan.id === selectedPlanId}
              onSelect={handlePlanSelect}
              onScrollToForm={handleScrollToForm}
              coordinates={selectedPosition}
              onFetchPoints={handleFetchPoints}
              selectedCityName={selectedCityName}
              onCitySelect={handleCitySelection} // Pass city selection handler
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant='body1' sx={{ color: colors.textGray, textAlign: 'center', marginY: 5 }}>Ceny netto. Do podanych cen należy doliczyć podatek VAT 23 %.</Typography>
      <SubscriptionDetail />
      <div ref={paymentFormRef}>
        <PaymentFormSection selectedPlan={selectedPlanName} />
      </div>

      {/* Map Popup */}
      <MapPopup
        open={mapPopupOpen}
        onClose={handleCloseMapPopup}
        position={selectedPosition}
        onLocationSelect={handleCitySelection}
        onConfirm={handleConfirmMapSelection}
        radius={selectedRadius}
      />
    </Box>
  );
};

export default SubscriptionList;
