import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

type Props = {
  content: string;
  title: string;
  imageSide: 'left' | 'right';
  imageUrl: string;
};

const AppContentSection: React.FC<Props> = ({ imageSide, title, content, imageUrl }) => {

  return (
    <Box sx={{ width: '100%', marginY: '50px' }}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {imageSide === 'left' && (
          <Grid item md={6}>
            <Box component="img" src={imageUrl} alt="NaviBud, wszystko w jednym miejscu" sx={{ maxWidth: '100%', height: 'auto' }} />
          </Grid>
        )}
        <Grid item xs={12} md={6}  padding={0}>
          <Typography variant="h4" paddingRight='15px' marginBottom='20px'>{title}</Typography>
          <Typography variant="body1" paddingRight='15px'>{content}</Typography>
        </Grid>
        {imageSide === 'right' && (
          <Grid item md={6}>
            <Box component="img" src={imageUrl} alt="Znajdź nowe możliwości z NaviBud (Navi-Bud)" sx={{ maxWidth: '100%', height: 'auto' }} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AppContentSection;
