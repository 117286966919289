export const Resource = {
  Buttons: {
    Contact: "buttons.contact",
    PriceList: "buttons.price_list",
    Login: "buttons.login",
    Submit: "buttons.submit",
    Cancel: "buttons.cancel",
    LearnMore: "buttons.learn_more",
    ContactUs: "buttons.contact_us",
    ReadMore: "buttons.read_more",
    Close: "buttons.close",
    SubmitContactForm: "buttons.submit_contact_form"
  },
  Marketing: {
    Box1: {
      Header: "marketing.box1.header",
      Text: "marketing.box1.text"
    },
    Box2: {
      Header: "marketing.box2.header",
      Text: "marketing.box2.text"
    },
    Box3: {
      Header: "marketing.box3.header",
      Text: "marketing.box3.text"
    }
  },
  Features: {
    Header: "features.header",
    Text1: "features.text_1",
    Text2: "features.text_2",
    Feature1: {
      Title: "features.feature_1.title",
      Description: "features.feature_1.description",
      ExtendedDescription: "features.feature_1.extended_description"
    },
    Feature2: {
      Title: "features.feature_2.title",
      Description: "features.feature_2.description",
      ExtendedDescription: "features.feature_2.extended_description"
    },
    Feature3: {
      Title: "features.feature_3.title",
      Description: "features.feature_3.description",
      ExtendedDescription: "features.feature_3.extended_description"
    },
    Feature4: {
      Title: "features.feature_4.title",
      Description: "features.feature_4.description",
      ExtendedDescription: "features.feature_4.extended_description"
    }
  },
  Benefits: {
    Title: "benefits.title",
    Text1: "benefits.text_1",
    Text2: "benefits.text_2",
    Benefit1: {
      Title: "benefits.benefit_1.title",
      Description: "benefits.benefit_1.description"
    },
    Benefit2: {
      Title: "benefits.benefit_2.title",
      Description: "benefits.benefit_2.description"
    },
    Benefit3: {
      Title: "benefits.benefit_3.title",
      Description: "benefits.benefit_3.description"
    },
    Benefit4: {
      Title: "benefits.benefit_4.title",
      Description: "benefits.benefit_4.description"
    },
    Benefit5: {
      Title: "benefits.benefit_5.title",
      Description: "benefits.benefit_5.description"
    }
  },
  ContactSection: {
    Title: "contact_section.title",
    Description: "contact_section.description",
    SupportText: "contact_section.support_text",
    ContactDetails: {
      PhoneLabel: "contact_section.contact_details.phone_label",
      EmailLabel: "contact_section.contact_details.email_label"
    },
    Form: {
      FormTitle: "contact_section.form.form_title",
      EmailPlaceholder: "contact_section.form.email_placeholder",
      MessagePlaceholder: "contact_section.form.message_placeholder",
      PrivacyPolicy: "contact_section.form.privacy_policy"
    }
  },
  Footer: {
    Login: "footer.login",
    PriceList: "footer.price_list",
    PrivacyPolicy: "footer.privacy_policy",
    TermsConditions: "footer.terms_conditions",
    CopyRights: "footer.copy_rights"
  },
  CtaTestSection: {
    Title: "cta_test_section.title",
    Subtitle: "cta_test_section.subtitle",
    CtaButton: {
      Text: "cta_test_section.cta_button.text",
      Tooltip: "cta_test_section.cta_button.tooltip"
    }
  },
  Messages: {
    Success: {
      MessageSent: "messages.success.message_sent"
    },
    Error: {
      SendingFailed: "messages.error.sending_failed",
      GeneralError: "messages.error.general_error"
    },
    Info: {
      Loading: "messages.info.loading",
      ContactingServer: "messages.info.contacting_server"
    },
    Warning: {
      UnsavedChanges: "messages.warning.unsaved_changes"
    }
  }
} as const;
