import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import MapComponent, { Position } from '../Map/MapComponent';

interface MapPopupProps {
  open: boolean;
  onClose: () => void;
  position: Position;
  onLocationSelect: (position: Position, cityName: string | null) => void;
  onConfirm: (position: Position, cityName: string | null) => void;
  radius: number | null;
}

const MapPopup: React.FC<MapPopupProps> = ({ open, onClose, position, onLocationSelect, onConfirm, radius }) => {
  const [localPosition, setLocalPosition] = useState<Position>(position);
  const [localCityName, setLocalCityName] = useState<string | null>(null); // Track city name locally

  const handleConfirm = () => {
    onConfirm(localPosition, localCityName);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          overflow: 'hidden', // Prevents the scrollbar
        },
      }}
    >
      <DialogTitle>Wybierz Lokalizację</DialogTitle>
      <DialogContent sx={{ p: 2, height: { xs: '50vh', lg: '54vh' } }}> {/* Adjust height for mobile and desktop */}
        <Box sx={{ mt: 1, height: '400px' }}>
          <MapComponent
            position={localPosition}
            onLocationSelect={(pos, cityName) => {
              setLocalPosition(pos);
              setLocalCityName(cityName); // Set city name when location is selected
            }}
            radius={radius}
            mapHeight="400px" // Make sure map takes full height of the container
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2, justifyContent: 'space-between' }}>
        <Button onClick={onClose} color="secondary">
          Anuluj
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Zatwierdź
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MapPopup;
