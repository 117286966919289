import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  textContent: string;
};

const Logo: React.FC<Props> = ({ textContent }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '38vh',
        marginTop: '16vh'
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontSize: {
            xs: '5.0rem',
            sm: '6.0rem',
            md: '7.0rem',
            lg: '7.5rem',
            xl: '8.0rem'
          },
          fontWeight: 'bold',
          textAlign: 'center',
          mb: '5vh',
          color: '#0e117f'
        }}
      >
        NaviBud
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: {
            xs: '1.5rem',
            sm: '1.8rem',
            md: '2.0rem',
            lg: '2.5rem',
            xl: '3.0rem'
          },
          textAlign: 'center',
          mb: '30px',
        }}
      >
        {textContent}
      </Typography>
    </Box>
  );
};

export default Logo;
