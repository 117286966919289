import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme, useMediaQuery, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import coloredMap from '../../content/images/keyFeatures/coloredMap_white.webp';
import editMarker from '../../content/images/keyFeatures/editMarker_white.webp';
import filtersImg from '../../content/images/keyFeatures/filters.webp';
import navigationImg from '../../content/images/keyFeatures/navigation.webp';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

const KeyFeaturesSection: React.FC = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState<null | typeof keyFeatures[0]>(null);
    const { t } = useTranslation();

    const handleClickOpen = (feature: typeof keyFeatures[0]) => {
        setSelectedFeature(feature);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedFeature(null);
    };

    const keyFeatures = [
        {
            title: `${t(Resource.Features.Feature1.Title)}`,
            content: `${t(Resource.Features.Feature1.Description)}`,
            moreContent: `${t(Resource.Features.Feature1.ExtendedDescription)}`,
            img: coloredMap,
        },
        {
            title: `${t(Resource.Features.Feature2.Title)}`,
            content: `${t(Resource.Features.Feature2.Description)}`,
            moreContent: `${t(Resource.Features.Feature2.ExtendedDescription)}`,
            img: filtersImg,
        },
        {
            title: `${t(Resource.Features.Feature3.Title)}`,
            content: `${t(Resource.Features.Feature3.Description)}`,
            moreContent: `${t(Resource.Features.Feature3.ExtendedDescription)}`,
            img: editMarker
        },
        {
            title: `${t(Resource.Features.Feature4.Title)}`,
            content: `${t(Resource.Features.Feature4.Description)}`,
            moreContent: `${t(Resource.Features.Feature4.ExtendedDescription)}`,
            img: navigationImg
        }
    ];
    

    return (
        <Box sx={{ width: '100%', marginBottom: '10px' }}>
            <Typography variant="h3" component="h2" gutterBottom
                sx={{
                    fontSize: {
                        xs: '1.8rem',
                        md: '2.5rem',
                        lg: '3.0rem'
                    },
                    mb: 5,
                    textAlign: 'center'
                }}>
                {t(Resource.Features.Header)}
            </Typography>
            <Typography variant="body1"
                sx={{
                    color: '#333',
                    lineHeight: 1.8,
                    fontSize: {
                        xs: '1.0rem',
                        md: '1.2rem'
                    },
                    mb: '60px',
                    textAlign: 'center'
                }}>
                {t(Resource.Features.Text1)}
                <br />
                {t(Resource.Features.Text2)}
            </Typography>
            {keyFeatures.map((feature, index) => (
                <Grid container spacing={3} key={index} sx={{ width: '100%', marginLeft: 0, mb: 4, backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff', paddingRight: 4 }}>
                    <Grid item xs={12} md={8} padding={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <Box
                            component="img"
                            sx={{
                                width: isSmallScreen ? '100%' : '80%',
                                height: isSmallScreen ? 'auto' : 'auto',
                                maxWidth: '100%',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                transition: 'transform 0.3s',
                                '&:hover': {
                                    transform: 'scale(1.05)'
                                }
                            }}
                            src={feature.img}
                            alt={feature.title}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: theme.spacing(3) }}>
                        <Typography variant="h5" component="p" sx={{ mb: 2, color: '#0e4999', fontWeight: 'bold' }}>
                            {feature.title}
                        </Typography>
                        <Typography variant="body1" textAlign={'justify'}>
                            {feature.content}
                        </Typography>
                        <Button
                            size="small"
                            startIcon={<InfoIcon />}
                            sx={{ fontSize: '0.875rem', color: '#ff7f11', mt: 2, mb: 4 }}
                            onClick={() => handleClickOpen(feature)}
                        >
                           {t(Resource.Buttons.ReadMore)}
                        </Button>
                    </Grid>
                </Grid>
            ))}

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{selectedFeature?.title}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {selectedFeature?.moreContent}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    {t(Resource.Buttons.Close)}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default KeyFeaturesSection;
