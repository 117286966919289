import React, { forwardRef } from 'react';
import {
    Box, Button, TextField, Typography, Grid, Snackbar, Alert, Backdrop, CircularProgress,
    Link,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AlertColor } from '@mui/material/Alert';
import { colors } from '../../theme';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

interface PaymentFormValues {
    name: string;
    email: string;
    company: string;
    address: string;
    plan: string;
    nip: string;
}

interface PaymentFormSectionProps {
    selectedPlan: string | null;
}

const paymentValidationSchema = Yup.object({
    name: Yup.string().required('Imię i nazwisko jest wymagane'),
    email: Yup.string().email('Niepoprawny adres email').required('Email jest wymagany'),
    company: Yup.string().required('Nazwa firmy jest wymagana'),
    address: Yup.string().required('Adres jest wymagany'),
    plan: Yup.string().required('Plan jest wymagany'),
    nip: Yup.string().required('NIP jest wymagany'),
});

const PaymentFormSection = forwardRef<HTMLDivElement, PaymentFormSectionProps>(({ selectedPlan }, ref) => {
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>('success');
    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            company: '',
            address: '',
            plan: selectedPlan || '',
            nip: ''
        },
        validationSchema: paymentValidationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true);
            handleFormSubmit(values, setSubmitting, resetForm);
        },
    });

    const handleFormSubmit = async (values: PaymentFormValues, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
        try {
            const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.SUBSCRIBE}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (!response.ok) throw new Error('Failed to process subscription');

            setSnackbarMessage('Twoje zamówienie zostało przyjęte! Sprawdź swoją skrzynkę pocztową.');
            setSnackbarSeverity('success');
            resetForm();
        } catch (error) {
            setSnackbarMessage('Wystąpił błąd podczas przetwarzania zamówienia.');
            setSnackbarSeverity('error');
        } finally {
            setSubmitting(false);
            setOpenSnackbar(true);
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Grid container spacing={2}  sx={{ ml: 0, width: '100%', mt: 14, mb: 2 }}>
            <Grid item xs={12} md={6} sx={{ bgcolor: colors.lightGray, pr: 8 }}>
                <Box sx={{ p: { xs: 2, md: 3 }, paddingLeft: 0 }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>Zamów Subskrypcję</Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Wypełnij formularz, aby zamówić wybrany plan subskrypcji. Po weryfikacji otrzymasz fakturę na podany adres email.
                    </Typography>
                    <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Jak zamówić subskrypcję?</Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        1. <strong>Wybierz plan</strong>: Wybierz odpowiedni plan subskrypcji, który najlepiej odpowiada Twoim potrzebom.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        2. <strong>Wypełnij formularz</strong>: Podaj swoje dane kontaktowe i firmowe w formularzu obok.
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        3. <strong>Złóż zamówienie</strong>: Kliknij przycisk "ZAMÓW" i oczekuj na potwierdzenie.
                    </Typography>
                    <Box sx={{ color: colors.textGray, display: 'flex', alignItems: 'center', mt: 4 }}>
                        <DescriptionOutlinedIcon sx={{ mr: 1 }} />
                        <Typography variant="body2">
                            Rejestrując się potwierdzasz, że zapoznałeś się z 
                            <Link href="/regulamin" sx={{ color: colors.warmOrange, textDecoration: 'none', ml: 0.5 }}>regulaminem NaviBud </Link> 
                            oraz akceptujesz jego warunki.
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid ref={ref} id="paymentSection" item xs={12} md={6} sx={{ padding: 2, display: 'flex', justifyContent: { xs: 'center', md: 'flex-end' } }}>
                <Box sx={{ p: { xs: 2, md: 3 }, paddingLeft: 0 }}>
                    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Typography variant="h5" sx={{ mb: 2 }}>Formularz zamówienia</Typography>
                        <TextField
                            fullWidth
                            name="name"
                            label="Imię i nazwisko"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            name="email"
                            label="Email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            name="company"
                            label="Nazwa firmy"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            error={formik.touched.company && Boolean(formik.errors.company)}
                            helperText={formik.touched.company && formik.errors.company}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            name="nip"
                            label="NIP"
                            value={formik.values.nip}
                            onChange={formik.handleChange}
                            error={formik.touched.nip && Boolean(formik.errors.nip)}
                            helperText={formik.touched.nip && formik.errors.nip}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            name="address"
                            label="Adres"
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            name="plan"
                            label="Plan"
                            value={formik.values.plan}
                            onChange={formik.handleChange}
                            error={formik.touched.plan && Boolean(formik.errors.plan)}
                            helperText={formik.touched.plan && formik.errors.plan}
                            SelectProps={{ native: true }}
                            sx={{ mb: 2 }}
                        >
                            <option value="">{selectedPlan || 'Wybierz plan'}</option>
                            <option value="Lokalny Start">Lokalny Start - 400 PLN/netto</option>
                            <option value="Regionalny Rozwój">Regionalny Rozwój - 550 PLN/netto</option>
                            <option value="Maksymalny Zasięg">Maksymalny Zasięg - 800 PLN/netto</option>
                            <option value="Twój Plan">Twój Plan - Indywidualna wycena</option>
                        </TextField>
                        <Button type="submit" variant="contained" fullWidth sx={{ bgcolor: '#ff7f11', borderRadius: 0 }}>
                            Złóż Zamówienie
                        </Button>
                        {loading && (
                            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        )}
                    </form>
                </Box>
            </Grid>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
});

export default PaymentFormSection;
