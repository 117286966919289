import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import termsOfService from '../../json/termsOfService.json';

const TermsOfService: React.FC = ()=> {
  const sections = termsOfService.sections;

  return (
    <Container sx={{paddingY: 8}}>
      <Typography variant="h4" gutterBottom sx={{mb: 2}}>{termsOfService.title}</Typography>
      {sections.map((section, index) => (
        <Box key={index}>
          <Typography variant="h6">{section.title}</Typography>
          <Typography sx={{pl: 2}} paragraph style={{ whiteSpace: 'pre-line' }}>{section.content}</Typography>
        </Box>
      ))}
    </Container>
  );
};

export default TermsOfService;
