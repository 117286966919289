import React from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';

type Props = {
  boxContent: string;
  additionalContent: string;
  boxAlignment: 'left' | 'right' | 'full';
  boxContentAlignment: 'flex-start' | 'center' | 'flex-end' | 'justify';
  background: string;
  textColor: string;
};

const MarketingBox: React.FC<Props> = ({
  boxContent,
  additionalContent,
  boxAlignment,
  boxContentAlignment,
  background,
  textColor,
}) => {
  const theme = useTheme();
  const isFullScreen = boxAlignment === 'full';
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isLandscape = useMediaQuery('(orientation: landscape)');

  return (

    <Grid container spacing={2}
      sx={{
        ml: 0, width: '100%',
        paddingTop: isSmallScreen && isLandscape ? 12 : 10,
      }}>
      {isFullScreen ? (
        // Pełnoekranowy box
        <Grid
          item
          xs={12}
          padding={2}
          sx={{ bgcolor: background }}>
          <Box
            sx={{
              p: { xs: 2, md: 3 },
              paddingLeft: 0,
              textAlign: boxContentAlignment,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, fontSize: '1.25rem', textAlign: 'justify' }}>
              {boxContent}
            </Typography>
          </Box>
        </Grid>
      ) : (
        // Domyślny układ box
        <Grid
          item
          xs={12}
          md={6}
          padding={2}
          order={{ xs: 2, md: boxAlignment === 'right' ? 2 : 1 }}
          sx={{
            bgcolor: background,
            paddingLeft: 0,
          }}
        >
          <Box
            sx={{
              p: { xs: 2, md: 3 },
              textAlign: boxContentAlignment,
              color: textColor,
            }}
          >
            <Typography variant="h6" sx={{ mb: 2, fontSize: '1.25rem' }}>
              {boxContent}
            </Typography>
          </Box>
        </Grid>
      )}

      {/* Dodatkowa zawartość */}
      {additionalContent && (
        <Grid item xs={12} md={isFullScreen ? 12 : 6} order={{ xs: 1, md: boxAlignment === 'left' ? 2 : 1 }}>
          <Box
            sx={{
              p: { xs: 3, md: 5 },
              pr: { xs: 3, md: 5 },
              textAlign: { xs: 'center', md: boxAlignment === 'full' ? 'center' : boxAlignment },
            }}
          >
            <Typography variant="body1" sx={{ fontSize: { xs: '1.25rem', md: '2rem', lg: '3rem' } }}>
              {additionalContent}
            </Typography>
          </Box>
        </Grid>
      )}


    </Grid>

  );
};

export default MarketingBox;
