import { useEffect, useRef, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Header from './components/Header/Header';
import Logo from './components/LogoSection/Logo';
import MarketingBox from './components/MarketingBox/MarketingBox';
import AppContentSection from './components/AppContentSection/AppContentSection';
import page6AppContentImage from './content/images/page6.webp';
import Footer from './components/Footer/Footer';
import ContactFormSection from './components/ContactFormSection/ContactFormSection';
import ArrowUpwardIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowScrollDownSection from './components/ArrowScrollDownSection/ArrowScrollDownSection';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import KeyFeaturesSection from './components/KeyFeatures/KeyFeaturesSection';
import NumberedSection from './components/NumberedSection/NumberedSection';
import { Box, IconButton, Typography } from '@mui/material';

import benefitImage1 from './content/images/benefits/1a.webp';
import benefitImage2 from './content/images/benefits/2a.webp';
import benefitImage3 from './content/images/benefits/3a.webp';
import benefitImage4 from './content/images/benefits/4a.webp';
import benefitImage5 from './content/images/benefits/5a.webp';
import TestAppForm from './components/TestAppSection/TestAppForm';
import SubscriptionList from './components/SubscriptionSection/SubscriptionList';
import TestAppBottomSection from './components/TestAppSection/TestAppBottomSection';
import { colors } from './theme';
import CookieConsentComponent from './components/CookieConsent/CookieConsentComponent';
import TermsOfService from './components/TermsOfService/TermsOfService';
import PaymentFormSection from './components/PaymentFormSection/PaymentFormSection';

import { useTranslation } from 'react-i18next';
import { Resource } from './resources';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Lexend Deca',
      'Helvetica',
      'sans-serif',
    ].join(','),
  },
});

function App() {
  const { t } = useTranslation();

  const contactRef = useRef<HTMLDivElement>(null);

  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="lg">
          <CookieConsentComponent />
          <Header contactRef={contactRef} />
          <Routes>
            <Route path='/polityka-prywatnosci' element={<PrivacyPolicy />} />
            <Route path="/regulamin" element={<TermsOfService />} />
            <Route path='/testuj-aplikacje' element={<TestAppForm />} />
            <Route path='/cennik' element={<SubscriptionList />} />
            <Route path='/' element={
              <>
                <Logo textContent={t(Resource.Marketing.Box1.Header)} />
                <MarketingBox
                  boxContent={t(Resource.Marketing.Box1.Text)}
                  boxContentAlignment='justify'
                  additionalContent=''
                  boxAlignment='full'
                  background='#f4f4f4'
                  textColor='' />
                <ArrowScrollDownSection />
                <TestAppBottomSection />

                <KeyFeaturesSection />
                <TestAppBottomSection />
                <Box
                  sx={{
                    border: '2px solid',
                    borderColor: colors.lightGray,
                    padding: { xs: 2, md: 4, lg: 8 },
                    marginTop: '16px',
                  }}
                >
                  <Typography
                    variant='h3'
                    component='h2'
                    sx={{
                      fontSize: {
                        xs: '1.8rem',
                        md: '2.5rem',
                        lg: '3.0rem'
                      },
                      mb: 2,
                      textAlign: 'center',
                    }}
                  >
                    {t(Resource.Benefits.Title)}
                  </Typography>
                  <Typography
                    variant='body1'
                    sx={{
                      fontSize: {
                        xs: '1.0rem',
                        md: '1.2rem'
                      },
                      mt: 2, mb: 2, textAlign: 'center'
                    }}
                  >
                    {t(Resource.Benefits.Text1)}
                    <br />
                    {t(Resource.Benefits.Text2)}
                  </Typography>

                  <NumberedSection
                    number={1}
                    side="left"
                    background="#f0f0f0" // Adjust the color to match your design
                    title={t(Resource.Benefits.Benefit1.Title)}
                    text={t(Resource.Benefits.Benefit1.Description)}
                    imageSrc={benefitImage1}
                  />
                  <NumberedSection
                    number={2}
                    side="right"
                    background="#ffffff" // Adjust the color to match your design
                    title={t(Resource.Benefits.Benefit2.Title)}
                    text={t(Resource.Benefits.Benefit2.Description)}
                    imageSrc={benefitImage2}
                  />
                  <NumberedSection
                    number={3}
                    side="left"
                    background="#f0f0f0" // Adjust the color to match your design
                    title={t(Resource.Benefits.Benefit3.Title)}
                    text={t(Resource.Benefits.Benefit3.Description)}
                    imageSrc={benefitImage3}
                  />
                  <NumberedSection
                    number={4}
                    side="right"
                    background="#ffffff" // Adjust the color to match your design
                    title={t(Resource.Benefits.Benefit4.Title)}
                    text={t(Resource.Benefits.Benefit4.Description)}
                    imageSrc={benefitImage4}
                  />
                  <NumberedSection
                    number={5}
                    side="left"
                    background="#f0f0f0" // Adjust the color to match your design
                    title={t(Resource.Benefits.Benefit5.Title)}
                    text={t(Resource.Benefits.Benefit5.Description)}
                    imageSrc={benefitImage5}
                  />
                </Box>
                <AppContentSection
                  title={t(Resource.Marketing.Box2.Header)} 
                  content={t(Resource.Marketing.Box2.Text)} 
                  imageSide='right'
                  imageUrl={page6AppContentImage}
                />

                <TestAppBottomSection />
                <MarketingBox
                  boxContent={t(Resource.Marketing.Box3.Text)} 
                  boxContentAlignment='justify'
                  additionalContent={t(Resource.Marketing.Box3.Header)} 
                  boxAlignment='left'
                  background='#0E4999'
                  textColor='#ffffff'
                />
                <ContactFormSection ref={contactRef} />
                {showTopBtn && (
                  <IconButton
                    aria-label='go up'
                    title="Przewiń do góry"
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                    sx={{
                      position: 'fixed',
                      bottom: '20px',
                      right: '18px',
                      color: colors.warmOrange,
                      zIndex: 1000,
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                      backgroundColor: 'white',
                      '&:hover': {
                        backgroundColor: colors.warmOrange,
                        color: '#fff',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
                      },
                    }}
                  >
                    <ArrowUpwardIcon fontSize='large' />
                  </IconButton>
                )}
              </>
            } />
          </Routes>
          <Footer />
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
