import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface NumberedSectionProps {
  number: number;
  side: 'left' | 'right';
  background: string;
  title: string;
  text: string;
  imageSrc: string;
}

const NumberedSection: React.FC<NumberedSectionProps> = ({
  number,
  side,
  background,
  title,
  text,
  imageSrc
}) => {
  const theme = useTheme();
  const isLeftSide = side === 'left';

  return (
    <Box
      sx={{
        background,
        p: 4,
        px: 4,
        display: 'flex',
        flexDirection: {xs: 'row', md: isLeftSide ? 'row' : 'row-reverse'},
        alignItems: 'start',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: {xs: 'flex-start', md: isLeftSide ? 'flex-start' : 'flex-end'},
          flex: '1 1 auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: { xs: 'flex-start', md: isLeftSide ? 'unset' : 'flex-end' },
            mb: 1,
          }}
        >
          <Typography
            variant="h2"
            component="div"
            sx={{
              fontWeight: 'bold',
              fontSize: '2.2rem',
              color: '#ff7f11',
              lineHeight: '1',
              mr: 2,
            }}
          >
            {number.toString().padStart(2, '0')}.
          </Typography>
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontSize: {md: '2.0rem', xs: '1.5rem'},
              maxWidth: { xs: '100%' },
              lineHeight: '1',
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: '1.0rem',
              md: '1.2rem'
            },
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: '0 1 auto',
          maxWidth: '40%',
          height: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ml: isLeftSide ? 'auto' : 0,
          mr: isLeftSide ? 0 : 'auto',
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        }}
      >
        <Box
          component="img"
          src={imageSrc}
          sx={{ width: '100%', height: 'auto', p: 2, maxWidth: '180px', minWidth: '150px' }}
          alt={`Section ${number}`}
        />
      </Box>
    </Box>
  );
};

export default NumberedSection;
