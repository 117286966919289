import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardActions, Button, Typography, Box, Slider, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import theme, { colors } from '../../theme';
import { API_ENDPOINTS } from '../../utils/apiConfig';
import { useSpring, animated } from '@react-spring/web';
import { Position } from '../Map/MapComponent';

interface SubscriptionPlan {
  id: number;
  name: string;
  price: string;
  range: string;
  details: string[];
  color: string;
  inactiveColorLight: string;
  inactiveColorDark: string;
  hasSlider?: boolean;
  isBuyable?: boolean;
  radius: number;
}

interface SubscriptionCardProps {
  plan: SubscriptionPlan;
  selected: boolean;
  onSelect: (id: number, name: string, radius: number) => void;
  onScrollToForm: () => void;
  coordinates: Position;
  onFetchPoints: (planId: number, position: Position, radius: number) => void;
  selectedCityName: string | null;
  onCitySelect: (position: Position, cityName: string | null) => void; // New prop for city selection
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  plan, selected, onSelect, onScrollToForm, coordinates, onFetchPoints, selectedCityName, onCitySelect }) => {
  const [sliderValue, setSliderValue] = useState<number>(100);  // Default slider value
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [investmentCount, setInvestmentCount] = useState<number | null>(0);
  const [loading, setLoading] = React.useState(false);


  const animatedInvestmentCount = useSpring({
    from: { number: 0 },
    to: { number: investmentCount || 0 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    if (selected) {
      fetchInvestmentCount(coordinates.lat, coordinates.lng, plan.id === 4 ? sliderValue : plan.radius);
    }
  }, [selected, coordinates, plan.radius, sliderValue]);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);
  };

  const handleCardClick = () => {
    if (plan.id === 4) {
      setOpen(true);
    } else {
      const selectedPlan = `${plan.name} - ${plan.price}${selectedCityName ? ` - ${selectedCityName}` : ''}`;
      onSelect(plan.id, selectedPlan, plan.radius);
    }
  };

  const handleOrderButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCardClick(); // Ensure the plan is selected
    setOpen(false);
    onScrollToForm(); // Scroll to the form
  };

  const handleClose = () => {
    setOpen(false);
    onSelect(plan.id, plan.name, sliderValue);
  };

  const fetchInvestmentCount = async (lat: number, lng: number, distanceInKm: number) => {
    try {
      setLoading(true);
      const url = `${API_ENDPOINTS.BASE_URL}${API_ENDPOINTS.COUNT_INVESTMENTS}?latitude=${lat}&longitude=${lng}&distanceInKm=${distanceInKm}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) throw new Error('Failed to fetch investment count');

      const count = await response.json();
      setInvestmentCount(count);
    } catch (error) {
      //setSnackbarMessage('Wystąpił błąd podczas pobierania liczby budów.');
      //setSnackbarSeverity('error');
      setInvestmentCount(null);
    } finally {
      setLoading(false);
      //setOpenSnackbar(true);
    }
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          cursor: 'pointer',
          mb: 2,
          '&:hover': {
            boxShadow: 4,
          },
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          borderRadius: '4px',
          border: `${selected ? '2px' : '1px'} solid ${selected ? '#FF5722' : '#ccc'}`,
          boxShadow: selected ? 8 : 1,
          transition: 'box-shadow 0.1s',
        }}
        onClick={handleCardClick}
      >
        <Box sx={{
          color: '#000',
          textAlign: 'center',
          p: 2
        }}>
          <Typography variant="h5" component="div"
            sx={{
              fontSize: {
                xs: '1.3rem',
                md: '1.0rem',
                lg: '1.3rem'
              },
            }}>
            {plan.name}
          </Typography>
          <Typography variant="body1" component="div"
            sx={{
              fontSize: {
                xs: '1.6rem',
                md: '1.5rem',
              },
              fontWeight: 'bold',
              marginTop: 2,
              color: colors.warmOrange
            }}>
            {plan.price}
          </Typography>
          <Typography variant="body1" component="div"
            sx={{
              fontSize: {
                xs: '0.7rem',
                md: '0.8rem',
              },
              color: colors.textGray
            }}>
            miesięcznie
          </Typography>
        </Box>

        <CardContent sx={{
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <Box>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center'
              }}>
              {plan.range}
            </Typography>
            {plan.hasSlider && (
              <Box sx={{ mb: 0 }}>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: 'center'
                  }}>Zasięg: do {sliderValue} km</Typography>
              </Box>
            )}
            <Typography
              variant="body2"
              sx={{
                mt: 2,
                textAlign: 'center'
              }}
            >
              Liczba budów w okolicy
              <br />
              <animated.span
                style={{
                  fontSize: '1.3rem',
                  color: colors.warmOrange,
                  fontWeight: 'bold'
                }}
              >
                {animatedInvestmentCount.number.to((n) => n.toFixed(0))}
              </animated.span>
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            bgcolor: '#fff',
            justifyContent: 'center',
            p: 1,
            flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons vertically on smaller screens
          }}
        >
          <Button
            sx={{
              borderColor: colors.warmOrange,
              color: '#fff',
              backgroundColor: colors.warmOrange,
              width: { xs: '100%', sm: '60%' }, // Full width on small screens, 60% on larger screens
              mt: 1,
              '&:hover': {
                borderColor: colors.warmOrange,
                bgColor: colors.warmOrange,
                color: colors.textGray
              },
              borderRadius: 0,
            }}
            size="small"
            variant="outlined"
            onClick={handleOrderButtonClick}
          >
            ZAMÓW
          </Button>
        </CardActions>
      </Card>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Ustaw zasięg</DialogTitle>
        <DialogContent>
          <Typography variant="body2">Zasięg: {sliderValue} km</Typography>
          <Slider
            value={sliderValue}
            onChange={handleSliderChange}
            aria-labelledby="continuous-slider"
            min={0}
            max={200}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ustaw
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubscriptionCard;
