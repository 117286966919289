import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid, Tooltip } from '@mui/material';
import { colors } from '../../theme';
import { useTranslation } from 'react-i18next';
import { Resource } from '../../resources';

const TestAppBottomSection = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Box sx={{ padding: 4, backgroundColor: colors.warmOrange, textAlign: 'center', my: 6, borderRadius: 0 }}>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Typography variant="h5" gutterBottom sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                        {t(Resource.CtaTestSection.Title)}
                    </Typography>
                    <Typography sx={{ mb: { xs: 3, md: 0 }, color: '#ffffff' }}>
                        {t(Resource.CtaTestSection.Subtitle)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Tooltip title={t(Resource.CtaTestSection.CtaButton.Tooltip)}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#ffffff',
                                color: '#000000',
                                borderRadius: 0,
                                padding: '10px 20px',
                                '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                },
                                fontWeight: 'bold'
                            }}
                            onClick={() => navigate('/testuj-aplikacje')}
                        >
                            {t(Resource.CtaTestSection.CtaButton.Text)}
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TestAppBottomSection;
