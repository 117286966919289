import React from 'react';
import { Box, Typography, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import StarIcon from '@mui/icons-material/Star';
import DatasetIcon from '@mui/icons-material/Dataset';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { colors } from '../../theme';

const features = [
  {
    name: 'Inteligentne mapy',
    icon: <TipsAndUpdatesIcon sx={{ color: colors.warmOrange }} />,
    description: 'Kolorowanie map wg statusów budów, umożliwiające łatwe śledzenie postępu prac i szybkie podejmowanie decyzji.'
  },
  {
    name: 'Zaawansowane filtrowanie',
    icon: <StarIcon sx={{ color: colors.warmOrange }} />,
    description: 'Precyzyjne wyszukiwanie budów według różnych kryteriów, zapewniające szybki dostęp do kluczowych informacji.'
  },
  {
    name: 'Gromadzenie danych o budowie',
    icon: <DatasetIcon sx={{ color: colors.warmOrange }} />,
    description: 'System CMS do zarządzania informacjami o budowie, pozwalający na kompleksowe zbieranie i aktualizowanie danych przez użytkowników.'
  },
  {
    name: 'Planowanie tras',
    icon: <AltRouteIcon sx={{ color: colors.warmOrange }} />,
    description: 'Optymalizacja tras do miejsc budowy, zmniejszająca czas i koszty dojazdu oraz zwiększająca efektywność operacyjną.'
  },
  {
    name: 'Historia działań',
    icon: <ManageHistoryIcon sx={{ color: colors.warmOrange }} />,
    description: 'Rejestracja wszystkich zmian w projekcie, zapewniająca pełną historię edycji i zwiększająca przejrzystość działań.'
  },
  {
    name: 'Zarządzanie ofertami',
    icon: <LocalOfferIcon sx={{ color: colors.warmOrange }} />,
    description: 'Monitorowanie złożonych ofert klientom, ułatwiające skuteczne zarządzanie procesem sprzedaży i budowanie relacji z klientami.'
  }
];


const SubscriptionDetail: React.FC = () => {
  return (
    <Box sx={{ my: 4, textAlign: 'center' }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 6 }}>
        Funkcje jakie oferujemy w każdym z planów
      </Typography>
      <Box sx={{ border: `1px solid ${colors.lightGray}`, borderColor: colors.lightGray, bgcolor: colors.lightGray, mt: 2 }}>
        <Grid container spacing={2} sx={{ ml: 0, width: '100%' }}>
          {features.map((feature, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              key={index}
              sx={{
                backgroundColor: '#fff',
                padding: 2,
                border: `1px solid ${colors.lightGray}`
              }}
            >
              <ListItem>
                <ListItemIcon>
                  {feature.icon}
                </ListItemIcon>
                <ListItemText primary={feature.name} secondary={feature.description}/>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionDetail;
