import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import cookiesConfig from '../../json/cookiesConfig.json'; // Import konfiguracji cookies
import { CookiesConfig } from '../../utils/types';
import { colors } from '../../theme';

const handleAccept = () => {
  const config: CookiesConfig = cookiesConfig; // Zadeklaruj typ dla cookiesConfig

  Object.keys(config).forEach(category => {
    config[category as keyof CookiesConfig].cookies.forEach(cookie => {
      if (category === 'statistics') {
        Cookies.set(cookie, 'true', { expires: 30 });
      } else if (category === 'marketing') {
        Cookies.set(cookie, 'true', { expires: 30 });
      }
      // Dodaj inne kategorie w razie potrzeby
    });
  });
};

const handleDecline = () => {
  const config: CookiesConfig = cookiesConfig; // Zadeklaruj typ dla cookiesConfig

  Object.keys(config).forEach(category => {
    config[category as keyof CookiesConfig].cookies.forEach(cookie => {
      Cookies.remove(cookie);
    });
  });
};

const CookieConsentComponent = () => (
  <CookieConsent
    cookieName="Consent"
    location="bottom"
    buttonText="Akceptuje wszystkie"
    declineButtonText="Odrzuć"
    setDeclineCookie={true}
    enableDeclineButton={true}
    hideOnDecline={false}
    onAccept={handleAccept} // Funkcja obsługująca akceptację
    onDecline={handleDecline} // Funkcja obsługująca odrzucenie
    style={{ background: '#555' }}
    buttonStyle={{ color: "#fff", fontSize: "14px", backgroundColor: colors.warmOrange }}
    declineButtonStyle={{ color: "#fff", fontSize: "14px", backgroundColor: "#000" }}
    overlay={true}
  >
    Ta strona korzysta z plików cookies. Poprzez kliknięcie na „Akceptuje wszystkie" jest wyrażona zgoda na przechowywanie plików cookie na swoim urządzeniu w celu usprawnienia korzystania z nawigacji strony, analizowania wykorzystania strony i wsparcia naszych działań marketingowych.
    Aby dowiedzieć się więcej zapoznaj się z <a href="/polityka-prywatnosci" style={{ color: colors.warmOrange }}>regulaminem</a>.
  </CookieConsent>
);

export default CookieConsentComponent;
