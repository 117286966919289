import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ArrowScrollDownSection = () => {
    return (
        <div className="scroll-indicator">
            <button onClick={() => window.scrollBy({ top: window.innerHeight, behavior: 'smooth' })}>
                <ArrowDownwardIcon />
            </button>
        </div>
    );
};

export default ArrowScrollDownSection;