import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import privacyPolicy from '../../json/privacyPolicy.json';

const PrivacyPolicy: React.FC = () => {
  const sections = privacyPolicy.sections;

  return (
    <Container sx={{paddingY: 8}}>
      <Typography variant="h4" gutterBottom sx={{mb: 2}}>{privacyPolicy.title}</Typography>
      <Typography variant="subtitle1" sx={{mb: 2}}><strong>Data wejścia w życie:</strong> {privacyPolicy.effectiveDate}</Typography>
      <Typography paragraph>{privacyPolicy.introduction}</Typography>
      {sections.map((section, index) => (
        <Box key={index}>
          <Typography variant="h6">{section.title}</Typography>
          <Typography sx={{pl: 2}} paragraph style={{ whiteSpace: 'pre-line' }}>{section.content}</Typography>
        </Box>
      ))}
    </Container>
  );
};

export default PrivacyPolicy;
